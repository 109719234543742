import axios from 'axios'

export default {
  index(currentPage = null, query: any = null, is_paginate = true) {
    return new Promise((resolve, reject) => {
      let params = {page: currentPage, pagination: is_paginate}
      params = {...query, ...params}
      axios.get(process.env.VUE_APP_API_BASE_URL + '/ext-cbs-api-gateway/gw_app_info', {
        params: params
      }).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      }).finally(() => {

      })
    })
  },

  store(payload: any) {
    return new Promise((resolve, reject) => {
      axios.post(process.env.VUE_APP_API_BASE_URL + '/ext-cbs-api-gateway/gw_app_info', payload)
        .then(response => {
          resolve(response.data)
        }).catch(error => {
        reject(error.response)
      })
    })
  },
  show(id: any) {
    return new Promise((resolve, reject) => {
      axios.get(process.env.VUE_APP_API_BASE_URL + '/ext-cbs-api-gateway/gw_app_info/' + id)
        .then(response => {
          resolve(response.data)
        }).catch(error => {
        reject(error.response)
      })
    })
  },
  update(id: any, payload: any) {
    return new Promise((resolve, reject) => {
      axios.put(process.env.VUE_APP_API_BASE_URL + '/ext-cbs-api-gateway/gw_app_info/' + id, payload)
        .then(response => {
          resolve(response.data)
        }).catch(error => {
        reject(error.response)
      })
    })
  },
  destroy(id: any) {
    return new Promise((resolve, reject) => {
      axios.delete('VUE_APP_API_BASE_URL/' + id)
        .then(response => {
          resolve(response.data)
        }).catch(error => {
        reject(error.response)
      })
    })
  },
  getOrganizationList: function () {
    return new Promise((resolve, reject) => {
      axios.get(process.env.VUE_APP_CBS_API_GATEWAY_API_BASE_URL + '/organization/list')
        .then(response => {
          resolve(response.data.data)
        })
        .catch((error) => {
          reject(error.response)
        })
    })
  }
}
