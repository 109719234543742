<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Gateway Application Info List</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{ name: 'Home' }">Home</router-link>
              </li>
              <li class="breadcrumb-item">Charging & Billing</li>
              <li class="breadcrumb-item">API GATEWAY</li>
              <li class="breadcrumb-item active">Gateway Application Info</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="container-fluid">
        <div class="card">
          <div class="card-header">
            <a
              href="javascript:void(0)"
              class="btn btn-info"
              @click="showFilter = !showFilter"
            ><i class="fa fa-filter"></i>Filter</a
            >
            <div class="card-tools">
              <router-link :to="{ name: 'ApiGatewayApplicationForm' }" class="btn btn-success"
              ><i class="fas fa-plus"></i> New
              </router-link>
            </div>
          </div>
          <form @submit.prevent="getItems" method="GET" novalidate>
            <div class="card-body" v-if="showFilter">
              <div class="row">
                <div class="col-12">
                  <div class="form-group row">
                    <label class="col-2 col-form-label" for="name">Name</label>
                    <div class="col-9">
                      <input
                        type="text"
                        id="name"
                        class="form-control"
                        v-model="filter.name"
                        placeholder="Name"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-10 offset-1">
                  <button type="submit" class="btn btn-primary m-2px">Search</button>
                  <button type="reset" class="btn btn-warning" @click="resetFilter()">
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </form>
          <div class="overlay" v-if="is_loading">
            <i class="fas fa-2x fa-sync-alt fa-spin"></i>
          </div>
          <div class="card-body table-responsive p-0">
            <div class="col-12">
              <table class="table">
                <thead>
                <tr>
                  <th style="width: 10px">ID</th>
                  <th>Client-Id</th>
                  <th>Organization</th>
                  <th>Name</th>
                  <th>Status</th>
                  <th>Client-Secret</th>
                  <th class="text-right">Action</th>
                </tr>
                </thead>
                <tbody class="CBSAccount-list">
                <tr v-for="(item, index) in items.data">
                  <td>{{ item.app_id }}</td>
                  <td>{{ item.app_ref_id }}</td>
                  <td>{{ item.organization_id }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.status }}</td>
                  <td>{{ item.token }}</td>
                  <td class="text-right">
                    <router-link
                      :to="{
                          name: 'ApiGatewayApplicationEdit',
                          params: { id: item.app_id },
                        }"
                      class="btn btn-info btn-sm m-2px"
                    >
                      <i class="fas fa-pencil-alt"></i>
                    </router-link>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>

            <div class="p-4" v-if="_.isEmpty(items.data)">
              <div class="alert alert-default-warning" role="alert">No data found!</div>
            </div>
          </div>
          <div class="card-footer">
         <vue-pagination
              :pagination="items"
              v-if="items.total > items.per_page"
              @paginate="getItems()"
            />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import MasterLayout from "@/components/layouts/MasterLayoutComponent";
import VuePagination from "@/components/partials/PaginationComponent";
import CBSApiGateWayApplicationInfo from "@/services/api/cbs-api-gateway/CBSApiGateWayApplicationInfo";

export default {
  name: "ApiGateWayApplicationList",
  components: {
    VuePagination,
  },
  data: () => ({
    items: {
      total: 0,
      per_page: 1,
      from: 1,
      to: 0,
      current_page: 1,
    },
    showFilter: false,
    filter: {},
    is_loading: false,
  }),
  methods: {
    getItems() {
      this.is_loading = true;
      CBSApiGateWayApplicationInfo.index(this.items.current_page, this.filter)
        .then((items) => {
          this.items = items;
        })
        .finally(() => {
          this.is_loading = false;
        });
    },
    resetFilter() {
      this.items.current_page = 1;
      this.filter = {};
      this.getItems();
    },
  },
  mounted() {
    this.getItems();
  },
  created() {
    this.$emit("update:layout", MasterLayout);
  },
};
</script>
